import React from 'react';
import i18next from 'i18next';
import { config as i18NextConfig } from '../translations/index';
import store from '../redux/store';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import Router from './router';

i18next.init(i18NextConfig);

const App = () => {
    return (
        <Provider store={store}>
            <I18nextProvider i18n={i18next}>
                <Router />
            </I18nextProvider>
        </Provider>
    );
};
export default App;

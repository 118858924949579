// import Button from '@src/components/form/Button'
import { AppBar, Toolbar, IconButton, Typography, Stack, Button, useMediaQuery, useTheme, Grid, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import cookie from 'react-cookies';
import { RESET, SET_BACKDROP } from '@src/redux/ui/actions';
import RootState from '@src/interfaces/RootState';
import CatchingPokemonIcon from '@mui/icons-material/CatchingPokemon';
import Drawer from './Drawer';
import CustomButton from '../form/CustomButton';

function RootLayout() {
    const [isClaim, setIsClaim] = useState<Record<string, any>>();
    const [margin, setMargin] = useState<Record<string, any>>();
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const dispatchStore = useDispatch();
    const path = window.location.pathname;
    const languageId = sessionStorage.getItem('languageId') as string;

    const isAuthenticated = useSelector((state: RootState) => state?.auth?.isAuthenticated);
    // const pages = ['Dashboard', 'Profile', 'Claim']
    const theme = useTheme();

    const isMatch = useMediaQuery(theme.breakpoints.down('md'));
    const handleLogout = () => {
        // TODO Handling logout from SAGA -> session clear, reset redux. Useful when login expires
        sessionStorage.clear();
        dispatchStore({ type: RESET });
        // authDispatch({ type: 'SIGNED_OUT' });
        dispatchStore({ type: 'SIGNED_OUT', val: false });
        cookie.remove('token_ethias');
        navigate('/');
    };

    useEffect(() => {
        if (path.includes('claim')) {
            setIsClaim({ xs: 'fixed', lg: 'relative', zIndex: '1000' });
            setMargin({ xs: '50px', lg: '50px' });
        } else {
            setIsClaim({ xs: 'relative', lg: 'relative' });
            setMargin({ xs: '200px', sm: '500px', lg: '50px' });
        }
    }, [path]);
    // console.log('PATH', path);

    return (
        <div style={{ padding: 0 }}>
            <header className="navigation" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="logoContainer">
                    <img src="/images/ethiaslogo.png" />
                    {languageId == 'fr' ? <p>Nous sommes là pour vous</p> : <p>we zijn er voor je</p> }
                </div>
                {/* <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginRight: '50px' }}>
                    <CustomButton
                        sx={{ display: 'block', padding: { xs: '9px 6px', lg: '12px 24px' }, width: { lg: '200px', xs: '120px' }, height: { lg: '50px', sx: '25px' }, borderRadius: '30px' }}
                        buttonType={'button'}
                    >
                        {t('login.need_help')}
                    </CustomButton>
                </div> */}
            </header>
            <Grid sx={{ flexGrow: 1 }} container justifyContent="center">
                <Grid item xs={12} sx={{ mt: 5 }}>
                    <Outlet />
                </Grid>
            </Grid>
            <Box
                sx={{
                    textAlign: 'right',
                    padding: { xs: '0px 5px 5px', lg: '10px 50px' },
                    marginTop: margin,
                    fontSize: '14px',
                    position: isClaim,
                    bottom: -1,
                    left: 0,
                    right: '10px',
                }}
            >
                <a
                    style={{ color: '#0098d1' }}
                    href={`${languageId == 'nl' ? 'https://www.ethias.be/part/nl/wettelijk/privacy-chart.html' : 'https://www.ethias.be/part/fr/legal/vie-privee.html'}`}
                    target="_blank"
                >
                    {t('login.access_condition')}
                </a>{' '}
                |
                <a
                    style={{ color: '#0098d1' }}
                    href={`${languageId == 'nl' ? 'https://www.ethias.be/part/nl/wettelijk/privacy-chart.html' : 'https://www.ethias.be/part/fr/legal/vie-privee.html'}`}
                    target="_blank"
                >
                    {t('login.privacy')}
                </a>{' '}
                |
                <a
                    style={{ color: '#0098d1' }}
                    href={`${languageId == 'nl' ? 'https://www.ethias.be/part/nl/wettelijk/privacy-chart.html' : 'https://www.ethias.be/part/fr/legal/vie-privee.html'}`}
                    target="_blank"
                >
                    {t('login.cookies')}
                </a>
            </Box>
        </div>
    );
}

export default RootLayout;
